// 本地环境配置
module.exports = {
  // old 
  // title: '天音视通——平台管理系统',
  // baseUrl: 'https://testp.tianyinvod.com', // 测试项目地址
  // baseApi: '/api', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // $cdn: ''

  // new 
  title: '天音视通——平台管理系统',
  baseUrl: 'https://testp.tianyinsaas.cn', // 测试项目地址 
  baseApi: '/api', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  $cdn: ''
};
