import api from './api'
import request from '../utils/request'


export function login(data) {
  return request({
    url: api.login,
    method: 'POST',
    data
  })
}

export function userInfo(data) {
  return request({
    url: api.userinfo,
    method: 'POST',
    data
  })
}
