import Cookies from 'js-cookie'

const TokenKey = 'Platform-Token';
const ossHttp = 'https://shouyin-oss.oss-cn-hangzhou.aliyuncs.com/';

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}


export function splitImageFileName(str) {
  return str.split(ossHttp)[1]
}
