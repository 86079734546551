import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/permission'
import './icons'
import './styles/index.less'
// import '@/utils/disableViewCode'
import directive from '@/directive/index'

Vue.use(ElementUI, {
  size: 'medium'
});
Vue.use(directive)

Vue.config.productionTip = false;
Vue.prototype.$cdn = 'https://shouyin-oss.oss-cn-hangzhou.aliyuncs.com/assets/';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
