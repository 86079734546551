import CryptoJS from 'crypto-js'
import qs from 'qs'
import ASCII from "@/utils/ASCII";

function setASCII(data) {
  return ASCII.sort_ascii(data)
}

//SHA1加密方法
export function encrypt(text) {
  let asciiStr = setASCII(JSON.parse(text));
  return CryptoJS.SHA1(asciiStr).toString().toLowerCase();
}

//解密方法 先解base64再解密
export function decrypt(text) {
  let ciphertext = window.atob(text);
  let decryptResult = CryptoJS.AES.decrypt(ciphertext, key, {    //  AES解密
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return JSON.parse(decryptResult.toString(CryptoJS.enc.Utf8));
}

//基于 axios 的请求方式进行的处理
export function handleEncryptedData({method, url, params, data}, type) {
  //加密
  if (type === 'encrypt') {
    let encryptionData = '';
    //如果是get或者delete请求方式，加密方式不一样
    if (method == 'GET' || method == 'DELETE') {
      if (params) {
        encryptionData = encrypt(qs.stringify(params));
        url = `${url}?${encryptionData}`;
        // 加密后需要置空 params 否则仍然会默认拼接到url上
        params = undefined
      }
      return {url, params}
    } else {
      // post put 请求方式加密
      encryptionData = encrypt(JSON.stringify(data));
      data = encryptionData;

      return {data}
    }
  } else {
    // 解密
    return JSON.parse(decrypt(arguments[0]))
  }
}
