import permission from "@/directive/permission";

const directives = {
  permission
};

export default {
  install(Vue){
    Object.keys(directives).forEach((key)=>{
      //注册所有自定义指令
      Vue.directive(key,directives[key])
    })
  }
}


/*
const install = function(Vue) {
  Vue.directive('permission', permission)
};

if (window.Vue) {
  window['permission'] = permission;
  Vue.use(install); // eslint-disable-line
}

permission.install = install;
export default permission
*/
