import store from '@/store'

function checkPermission(el, binding) {
  const { value } = binding;
  const perms = store.getters && store.getters.perms;
  // console.log(perms, 'perms');
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const requiredPerms = value; // DOM绑定需要的按钮权限标识

      const hasPerm = perms.some(perm => {
        return requiredPerms.includes(perm)
      });

      if (!hasPerm) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  } else {
    throw new Error(`need roles! Like v-permission="['admin','editor']"`)
  }
}

const permission = {
  bind(el,binding) {
    checkPermission(el, binding)
  },
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
};

export default permission
