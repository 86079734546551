const api = {
  login: "/platform/login",  //登录

  userinfo:'/platform/userdata',  //用户信息

  stores: '/platform/shop',  //门店列表
  add_stores: '/platform/shop/add',  //新增门店
  edit_stores: '/platform/shop/edit', //编辑门店
  update_status: '/platform/shop/statusedit', //更新状态

  merchants: '/platform/merchants',  //商家
  add_merchants: '/platform/merchants/add',  //新增商家
  edit_merchants: '/platform/merchants/edit',  //编辑商家
  update_merchants_status: '/platform/merchants/statusedit', //更新商家状态
  set_merchant_admin: '/platform/merchants/set_shopuser', //设置商家管理员
  edit_merchant_admin: '/platform/shopuser/edit', //编辑商家管理员
  update_pwd: '/platform/shopuser/editpass', //修改商家管理员密码

  versions: '/platform/version',  //版本
  add_version: '/platform/version/add',  //新增版本
  edit_version: '/platform/version/edit', //编辑版本
  update_version_status: '/platform/version/statusedit', //更新版本状态
  accredit_menu:'/platform/shopmenu',  //获取授权菜单
  accredit:'/platform/versionshopmenus/empower',   //授权

  upload_sign:'/common/oss/sign',   //上传


};
export default api;
