const key = "JOqA4dzZDych2oaTPsJksryVeahpbHWa";  //后端给出的密钥
var ASCII = {
  sort_ascii(obj) {
    let arr = [];
    let num = 0;
    for (let i in obj) {
      obj[i] = obj[i] === null ? '' : obj[i];
      if (typeof obj[i] !== "object") {
        if (typeof obj[i] == 'string') {
					obj[i] = obj[i].trim();
				}
				if (typeof obj[i] == 'number') {
					obj[i] = Number(obj[i].toString().trim())
				}
        arr[num] = i;
        num++;
      }
    }
    let sortArr = arr.sort();
    let str = ''; //自定义排序字符串

    for (let i in sortArr) {
      str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
    }
    //去除两侧字符串
    let chat = '&';
    str = str.replace(new RegExp('^\\' + chat + '+|\\' + chat + '+$', 'g'), '');
    str = `${str}&key=${key}`;
    return str;
  }
};

export default ASCII;
