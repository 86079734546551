import {constantRoutes,asyncRoutes} from '@/router'
import { getPermissionsApi } from '@/api/common'
import {Message} from "element-ui";
import {firstToUpper} from "@/utils/utils";
const Layout = () =>import('@/layout/index.vue');

/**
 * 使用meta.roles判断当前用户是否具有权限
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  });

  return res
}

const state = {
  routes: constantRoutes,
  addRoutes: [],
  perms: [],
  hasPerms: true,
  tabsActiveName: 1 //存储授权弹窗tabsActivceName上一次的值
};



const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_PERMS:(state,perms)=>{
    state.perms = perms;
  },
  DEL_ROUTES: (state, routes) => {
    state.addRoutes = [];
    state.routes = constantRoutes.concat([]);
    // localStorage.removeItem('storeData')
  },
  setHasPerms: (state, hasPerms) => {
    state.hasPerms = hasPerms
  },
  setTabsActiveName: (state, activeName) => {
    // let tempArr = []
    // tempArr.push(activeName)
    // const resArr = new Set(tempArr);
    // state.tabsActiveName = resArr

    state.tabsActiveName =activeName
  }
};

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes;
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // }
      getPermissionsApi().then(res => {
        // console.log(res,'rs');
        if (res.code === 1) {
          accessedRoutes = res.data[0]?.children;
          let newRoutes = [];
          let perms = [];
          accessedRoutes.map((item,index) => {
          
            let parentObj = {
              path:index===0?'/':'/'+item.web_url,
              component:Layout,
              meta:{
                affix:true,
                icon:item.icon,
                keepAlive:true,
                title:item.name,
                parentNode:item.path
              },
              redirect:'/'+item.children[0]?.web_url, 
              children:[],
            };
            // 只有一级的时候，做一个父级菜单
            if(!item.children.length){
              delete parentObj.redirect
              // console.log(4444,item);
              parentObj.redirect = index===0?'/':'/'+item.web_url, 
              parentObj.children = [{
                path:index===0?'/':'/'+item.web_url,
                name:firstToUpper(item.web_url),
                component: (resolve) => require([`@/views${item.component}`], resolve),
                meta: {
                  affix:true,
                  icon:item.icon,
                  keepAlive:true,
                  title:item.name,
                  parentNode:item.path,
                }
              }]
              item.below_button.map(btn => {
                btn.push_button_title = `${item.web_url}:${btn.push_button_title}`;
                perms.push(btn.push_button_title)
              })


            }
            item.children.map(child => {
              if (child.type == 1 || child.type==3) {
                let childObj = {
                  path:'/'+child.web_url,
                  name:firstToUpper(child.web_url),
                  component: (resolve) => require([`@/views${child.component}`], resolve),
                  meta: {
                    affix:true,
                    icon:child.icon,
                    keepAlive:true,
                    title:child.name,
                    parentNode:item.path,
                  }
                }
                child.below_button.map(btn => {
                  btn.push_button_title = `${child.web_url}:${btn.push_button_title}`;
                  perms.push(btn.push_button_title)
                })
                parentObj.children.push(childObj)
              }
           
            });
            newRoutes.push(parentObj)
          });
          if (newRoutes) {
            commit('setHasPerms',false)
          } else {
            commit('setHasPerms',true)
          }
          commit('SET_PERMS',perms);
          commit('SET_ROUTES', newRoutes);
          console.log(newRoutes,'newRoutes');
          resolve(newRoutes)
        }

      }).catch(err => {
        Message.error(err)
      })
      // commit('SET_ROUTES', accessedRoutes);
    
    })
  },
  generateRoutes_copy({commit}, data) {
    return new Promise((resolve, reject) => {
      let accessedRoutes;
      //let storeInfo = JSON.parse(data);
      menuspurview({category: 1, shop_id: data.id}).then(res => {
        if (res.code === 1) {
          accessedRoutes = res.data[0].children;
          console.log(accessedRoutes,'accessedRoutes+++++');
          let newRoutes = [];
          let perms = [];
          // if (!accessedRoutes.length) {
          //   localStorage.setItem('perArr',false)
          //   // router.push({
          //   //   path:'/4033'
          //   // })
          // } else {
            accessedRoutes.map((item,index) => {
              let parentObj = {
                path:index===0?'/':'/'+item.web_url,
                component:Layout,
                meta:{
                  affix:true,
                  icon:item.icon,
                  keepAlive:true,
                  title:item.name,
                  parentNode:item.path
                },
                redirect:'/'+item.children[0]?.web_url, 
                children:[],
              };
              if(!item.children){
                delete parentObj.redirect
              }
              item.children.map(child => {
                if (child.type == 1 || child.type==3) {
                  let childObj = {
                    path:'/'+child.web_url,
                    name:firstToUpper(child.web_url),
                    component: (resolve) => require([`@/views${child.component}`], resolve),
                    meta: {
                      affix:true,
                      icon:child.icon,
                      keepAlive:true,
                      title:child.name,
                      parentNode:item.path,
                    }
                  }
                  child.below_button.map(btn => {
                    btn.push_button_title = `${child.web_url}:${btn.push_button_title}`;
                    perms.push(btn.push_button_title)
                  })
                  parentObj.children.push(childObj)
                }
                // if (!child.click_status) {
                //   let childObj = {
                //     path:'/'+child.web_url,
                //     name:firstToUpper(child.web_url),
                //     component: (resolve) => require([`@/views${child.component}`], resolve),
                //     meta:{
                //       affix:true,
                //       icon:child.icon,
                //       keepAlive:true,
                //       title:child.name,
                //       parentNode:item.path,
                //     },
                //   };
                //   child.children.map(btn=>{
                //     if(btn.click_status){
                //       btn.push_button_title = `${child.web_url}:${btn.push_button_title}`;
                //       perms.push(btn.push_button_title)
                //     }
                //   });
                //   parentObj.children.push(childObj)
                // }
              });
              newRoutes.push(parentObj)
            });
          // }
          commit('SET_PERMS',perms);
          commit('SET_ROUTES', newRoutes);
          // if (!accessedRoutes.length) {
          //   router.push({
          //     path:'/403'
          //   })
          // }
          resolve(newRoutes)
        }
      }).catch(err => {
        Message.error(err)
      })
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

